<template>
  <div class="q-collapse">
    <div class="rounded py-2 px-4">
      <div class="" v-b-toggle="title">
        <span class="collapse-title d-flex justify-content-between"
          ><span class="">{{ title }}</span>
          <span class="when-opened">
            <i class="fa-solid fa-angle-up"></i>
          </span>
          <span class="when-closed">
            <i class="fa-solid fa-angle-down"></i>
          </span>
        </span>
      </div>
      <b-collapse :id="title">
        <div class="mt-3">
          <slot>
             No Record...
          </slot>
        </div>
       
      </b-collapse>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style scoped>
.q-collapse::after {
  content: "";
  width: 100%;
  display: block;
  border-radius: 4px;
  height: 1px;
  border: 1px solid rgb(206 206 210 / 25%);
}
.collapse-title {
  font-size: 18px;
  font-weight: 500;
}
.collapsed > .collapse-title > .when-opened,
:not(.collapsed) > .collapse-title > .when-closed {
  display: none;
}
</style>
